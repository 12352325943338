
import template from './conversation.html';

/**
 * Conversation Page view model
 */
class ConversationViewModel
{
	constructor(page)
	{
		this.page = page;
		this.element = page.element;
		this.conversation_uuid = ko.observable(page.bindings.conversation_uuid);
	}
}

/**
 * Conversation page class
 */
class ConversationClass
{
	constructor(bindings, element)
	{
		this.bindings = bindings;
		this.element = element;
		this.viewModel = new ConversationViewModel(this);
		this.name = 'ConversationClass';
	}

	async init()
	{

	}

	async updateData()
	{

	}

	async teardown()
	{}
}

export default {
	route: '[/]ui/messages/folders/conversation',
	page_class: ConversationClass,
	template: template
}
