
import Folders from './folders/folders.js';
import Folder from './folder/folder.js';
import Conversation from './conversation/conversation.js';
import SetupChannels from './setup/channels/channels.js';
import SetupTemplates from './setup/templates/templates.js';
import EditTemplate from './setup/templates/edit-template.js';
import EmailUserList from './setup/email-list-management/email-user-list.js';

export default [
	Folders,
	Folder,
	Conversation,
	SetupChannels,
	SetupTemplates,
	EditTemplate,
	EmailUserList
];
