import template from './dashboard-page.html';

class MessageDashboardViewModel
{
	constructor (page)
	{
		this.page = page;
		this.tabs = ko.observableArray([
			{
				route: '[/ui/messages/setup/]templates',
				title: 'Templates',
				icon: 'fa-regular fa-object-ungroup fa-lg'
			},
			{
				route: '[/ui/messages/setup/]channels',
				title: 'Channels',
				icon: 'fa-solid fa-circle-nodes fa-lg'
			}
		]); 
		this.current_route = ko.observable();
	}

	load_tab (route)
	{
		window.localStorage.setItem('last_message_tab', route);
		const element = document.querySelector('.message_dashboard_page');
		this.current_route(route);
		const page = Grape.pages.find_page_by_id(route);

		if (!page)
			throw new Error(`Page with id "${route}" not found`);

		Grape.pages.load_page(page, element, {}, {}, () => {});
	}
}

class MessageDashboardPage
{
	constructor(bindings)
	{
		this.viewModel = new MessageDashboardViewModel(this);
		this.bindings = bindings;
	}
	
	async init ()
	{
		document.title = '';
		let last_page = window.localStorage.getItem('last_message_tab');

		if (last_page)
			this.viewModel.load_tab(last_page);
		else
			this.viewModel.load_tab('');
	}
}

export default {
	route: '/grape-ui-dashboard/messages',
	page_class: MessageDashboardPage,
	template: template,
	name: 'messages-dashboard',
	title: 'Messages',
	page_id: 'dashboard.messages',
	icon: 'fa-regular fa-message-captions',
	idx: 5
}
