
import template from './message-address.html';

/**
 * Component for Message Address entry
 * Parameters:
 *  address
 *	role
 *	canEditAddress
 *	canEditRole
 *	onAdd
 *	onRemove
 */
class AddressEntryVM
{
	constructor(bindings)
	{
		this.bindings = bindings || {};

		this.address = ko_helper.safe_observable(bindings.name);
		this.addressRole = ko_helper.safe_observable(bindings.role, 'to');
		this.address_uuid = ko_helper.safe_observable(bindings.address_uuid);
		this.canEditAddress = bindings.canEditAddress || false;
		this.canEditRole = ko_helper.safe_observable(bindings.canEditRole || false);

		if (this.addressRole() == 'from')
			this.canEditRole(false);

		this.onAdd = bindings.onAdd;
		this.canAdd = (this.onAdd !== undefined) && this.onAdd instanceof Function;

		this.onRemove = bindings.onRemove;
		this.canRemove = (this.onRemove !== undefined) && (this.onRemove instanceof Function);

	}

	add_click()
	{
		if (this.canAdd)
			this.onAdd();
	}

	remove_click()
	{
		if (this.canRemove)
			this.onRemove({
				address_uuid: this.address_uuid(),
				role: this.addressRole(),
				name: this.address()
			});
	}

	updateAddressRole(addressRole)
	{
		this.addressRole(addressRole);
	}
}

export default {
	name: 'message-address',
	viewModel: AddressEntryVM,
	module_type: 'ko',
	template: template
};
