
import FolderList from '../../models/FolderList.js';

/**
 * Page with sidebar example
 */
import template from './folders.html';

class FoldersPageVM
{
	constructor (page)
	{
		this.page = page;
		this.loading = ko.observable(false);
		this.titleVisibility = ko.observable(true);

		this.folders = new FolderList();
		this.folder = ko.observable();
	}

	async load()
	{
		await this.folders.load();

		if (this.folders.list().length > 0)
			this.showFolder(this.folders.list()[0]);
	}

	async showFolder(folder){
		this.folder(folder);
		const route = "[/ui/messages/folders]";
		const element = this.page.element.querySelector(".ps-sidebar-content");
		const page = Grape.pages.find_page_by_id(route);
		if (!page)
			throw new Error(`Page with id "${route}" not found`);
		Grape.pages.load_page(page, element, {folder}, {}, () => {});
	}

	async createFolder_click()
	{
		let folder_name = await window.Grape.alerts.prompt({message: 'Enter new folder name'});
		if (!folder_name || !folder_name.prompt)
			return;

		folder_name = folder_name.prompt;

		let result = await Grape.fetches.postJSON('/api/messages/folder', {name: folder_name});
		if (result.status != 'OK')
		{
			await window.Grape.alerts.alert({
				type: 'error',
				title: 'Something went wrong',
				message: result.message || 'Unable to save folder'
			})
			return;
		}
		await this.load();

	}
}

class FoldersPage
{
	constructor (bindings, element, page)
	{
		this.viewModel = new FoldersPageVM(this);
		this.bindings = bindings;
		this.element = element;
	}

	async init ()
	{
		document.title = 'Folders';
		this.viewModel.load();
	}
}

export default {
	route: '[/]ui/messages/folders',
	page_class: FoldersPage,
	template: template
}
