
import FolderList from '../../models/FolderList.js';

/**
 * Page with sidebar example
 */
import template from './folder.html';

class FolderPageVM
{
	constructor (page)
	{
		this.page = page;
		this.loading = ko.observable(false);
		this.titleVisibility = ko.observable(true);

		this.folders = new FolderList();
		this.folder = ko_helper.safe_observable(this.page.bindings.folder);
	}

	async loadFolder(folder)
	{
		this.loading(true);
		await folder().loadMessages({
			msg_props: ['envelopedate', 'subject', 'contentcount', 'excerpt', 'body']
		});
		// this.folder(folder);
		this.loading(false);
	}
}

class FolderPage
{
	constructor (bindings, element, page)
	{
		this.bindings = bindings;
		this.element = element;
		this.viewModel = new FolderPageVM(this);
	}

	async init ()
	{
		document.title = `Folders - ${this.viewModel.folder().name()}`;
		this.viewModel.loadFolder(this.viewModel.folder);
	}
}

export default {
	route: '[/ui/messages/folders]',
	page_class: FolderPage,
	template: template
}
